import React from "react"
import { graphql } from "gatsby"
import DynamicZone from "../components/organisms/dynamic-zone"
const Page = ({ data: { strapiGetInvolved } }) => {
  return <DynamicZone components={strapiGetInvolved.containers} />
}

export const query = graphql`
  query GetInvolved($locale: String) {
    strapiGetInvolved(locale: { eq: $locale }) {
      name
      slug
      containers {
        ...DynamicZoneFragment
      }
    }
  }
`

export default Page
